import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section>
      <h2>Electronic Signature Sofware Application</h2>
      <p>eSignature software makes it simple to execute contracts, NDAs, and various legal forms within your web browser or on your mobile device.</p>
      <p>With an eSignature app, you can simply upload or scan a document to be signed, fill out form fields including signature locations yourself or request others to do the same, and download the finalized copy.</p>
      <p>eSignature apps save time, paper, and hassle.</p>
      <p>The most relevant laws related to electronic signatures are the <a href="https://www.fdic.gov/regulations/compliance/manual/10/x-3.1.pdf">E-Sign Act</a> and the <a href="https://en.wikipedia.org/wiki/EIDAS">eIDAS</a>.</p>
    </section>
    <hr/>
    <section>
      <h2>Use cases for eSignature Software:</h2>
      <ul>
        <li>Contracts</li>
        <li>NDAs</li>
        <li>Authorizations</li>
        <li>Consent forms</li>
        <li>Form W-9s, I-9s, 8879s</li>
        <li>Registration forms</li>
        <li>Release forms</li>
        <li>Direct deposit forms</li>
        <li>Offer letters</li>
        <li>HR acknowledgements</li>
        <li>Policy reviews</li>
        <li>Statements of work</li>
        <li>Proposals</li>
        <li>Receipts</li>
        <li>Expenses</li>
      </ul>
    </section>
    <hr/>
    <section>
      <h2>Benefits of eSignature Software:</h2>
      <ul>
        <li>Save the trees, use less paper</li>
        <li>Save time and hassle for your employees, vendors, and clients</li>
        <li>Stay professional for clients and partners</li>
        <li>Keep a log of activity and events</li>
        <li>Maintain industry compliance</li>
        <li>Friendly and easy-to-use interface</li>
      </ul>
    </section>
    <hr/>
    <section>
      <h2>Signup</h2>
      <p><a href="https://signups.wufoo.com/forms/zjhas602ym6nu">Signup</a> to be notified when our service launches!</p>
    </section>
    <hr/>
  </Layout>
)

export default IndexPage
